<!-- Change 13
to enable or disable real time customizer
 -->
<template>
  <layout-semi>

    <router-view/>

    <!-- override vertical navbar -->
    <!-- <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template> -->

    <!-- override vertical navbar -->
    <!-- <template
      #navbar
      :data-col="null"
    >
      <navbar
        data-col="1-column"
      />
    </template> -->
    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />
  </layout-semi>
</template>

<script>
import LayoutSemi from '@core/layouts/layout-semi/LayoutSemi.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
// import Navbar from '../components/Navbar.vue'

export default {
  components: {
    AppCustomizer,
    LayoutSemi,
    // Navbar,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
